<div class="container">

    <div class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8" style="text-align:center">
        </div>
        <div class="col-md-2">
        </div>
    </div>
    <div>

        <div class="row" style="margin-top: 7%">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <div class="row formBorder-css">
                    <form [formGroup]="khcForm" id="newForm">
                        <div class="row">

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-8 label">
                                        <span style="color:#EA0808;"><b>Note:</b></span> All Fields Are Mandatory Unless
                                        Specified
                                    </div>
                                    <div class="col-md-4">
                                    </div>
                                    <hr style="margin-top: 2%; color: #CCCCCC;">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6 col-css">

                                        <label class="label">Country</label>
                                        <select class="form-select form-select-md" aria-label="select example"
                                            [(ngModel)]="submitformmodel.country" formControlName="country" required>

                                            <option value="" selected disabled hidden> Please select country</option>
                                            <option [value]="item.country" *ngFor="let item of this.countryListGrid">
                                                {{item.country}}</option>
                                        </select>

                                    </div>
                                    <div class="col-md-6 col-css right_side_field-css">

                                        <label class="label">Your Name (so we know who submitted the great idea)</label>

                                        <input class="form-control form-control-md" type="text" maxlength="40"
                                            placeholder="Please enter your name so we know who submitted the great idea"
                                            formControlName="your_name" [(ngModel)]="submitformmodel.name"
                                            ngbTooltip="Tooltip on top" required>
                                        <!-- <p *ngIf="khcForm.controls['your_name'].invalid && khcForm.controls['your_name'].touched"  style=" font-size: medium; color: red ">
                                    Your Name is required
                                   </p> -->

                                    </div>
                                    <!-- <h1>{{submitformmodel.name}}</h1> -->
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">


                                    <div class="col-md-6 col-css">
                                        <label class="label">Your Email Address (so we can update you on your ideas
                                            progression)</label>


                                        <input class="form-control form-control-md" type="email"
                                            placeholder="Please enter your email address so we can update you on your idea's progression"
                                            maxlength="60" [(ngModel)]="submitformmodel.emailAddress"
                                            formControlName="email" required>
                                        <!-- <p *ngIf="khcForm.controls['your_email_address'].invalid && khcForm.controls['your_email_address'].touched"  style=" font-size: medium; color: red ">
                                    Your Email address is required
                                   </p> -->
                                        <div *ngIf="khcForm.controls['email'].invalid && khcForm.controls['email'].touched"
                                            style="color: red;">
                                            Email must be a valid email address
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">


                                    <div class="col-md-6 col-css">
                                        <label class="label">Your Department</label>
                                        <select class="form-select form-select-md" aria-label=".form-select-sm example"
                                            [(ngModel)]="submitformmodel.yourDepartment"
                                            formControlName="your_department" required>

                                            <option value="" selected disabled hidden> Please select your department
                                            </option>
                                            <option [value]="item.department"
                                                *ngFor="let item of  this.yourDepartmentGrid">{{item.department}}
                                            </option>

                                        </select>
                                        <!-- <p *ngIf="khcForm.controls['your_department'].invalid && khcForm.controls['your_department'].touched"  style=" font-size: medium; color: red ">
                                    Your Department is required
                                   </p> -->
                                    </div>
                                    <div class="col-md-6 col-css right_side_field-css">
                                        <label class="label">Idea Department</label>

                                        <select class="form-select form-select-md" aria-label="select example"
                                          
                                            formControlName="idea_department" #ideadepartmentname
                                            (change)="onSelectedIdeaDepart($event)" required>
                                            <option value="" selected disabled hidden>What area do you believe your idea
                                                sits within?</option>
                                            <option [attr.isClassification1Visible]="item.isClassfication1Visible"
                                                *ngFor="let item of this.ideaDepartmentGrid "
                                                [value]="item.ideaDepartment && item.id ">{{item.ideaDepartment}}
                                            </option>

                                        </select>


                                        <!-- <p *ngIf="khcForm.controls['idea_department'].invalid && khcForm.controls['idea_department'].touched"  style=" font-size: medium; color: red ">
                                    Idea Department is required
                                   </p> -->
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12"
>
                                <div class="row">
                                    <div class="col-md-6 col-css" *ngIf="isClassification1Visible">

                                        <label class="label">Idea Classification 1</label>
                                        <select class="form-select form-select-md" aria-label=".form-select-sm example"
                                            formControlName="idea_classification1" #ideaClassification1Value
                                            (change)="onSelectedIdeaClassification1($event)"
                                            required>
                                            <option value="" selected disabled hidden>Please select idea classification
                                            </option>
                                            <option [attr.isClassification2Visible]="item.isClassfication2Visible" [value]="item.classification1  && item.id"
                                                *ngFor="let item of  this.ideaClassification1">
                                                {{item.classification1}}</option>
                                        </select>
                                        <!-- <p *ngIf="khcForm.controls['idea_classification1'].invalid && khcForm.controls['idea_classification1'].touched"  style=" font-size: medium; color: red ">
                                    Idea Classification 1 is required
                                   </p> -->
                                    </div>
                                    <div class="col-md-6 col-css right_side_field-css"
                                        *ngIf="isClassification1Visible && isClassification2Visible">
                                        <label class="label">Idea Classification 2 </label>
                                        <select class="form-select form-select-md" aria-label=".form-select-sm example"
                                            [(ngModel)]="submitformmodel.ideaClassification2"
                                            formControlName="idea_classification2" required>
                                            <option value="" selected disabled hidden>Please select idea classification
                                            </option>
                                            <option [attr.isClassification3Visible]="item.isClassfication3Visible" [value]="item.classification2"
                                                *ngFor="let item of this.ideaClassification2">
                                                {{item.classification2}}</option>

                                        </select>
                                        <!-- <p *ngIf="khcForm.controls['idea_classification2'].invalid && khcForm.controls['idea_classification2'].touched"  style=" font-size: medium; color: red ">
                                    Idea Classification 2 is required
                                   </p> -->
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">


                                    <div class="col-md-6 col-css" >
                                        <label class="label">Idea Business Area </label>
                                        <select class="form-select form-select-md" aria-label=".form-select-sm example"
                                            formControlName="idea_business_area" #ideabusinessArea
                                            (change)="onSelectedIdeaBusinessArea($event)" required>
                                            <option value="" selected disabled hidden>Please select idea business area
                                            </option>
                                            <option [attr.isClassification3Visible]="item.isClassfication3Visible"  [value]="item.id"
                                                *ngFor="let item of  this.ideaBusinessArea">{{item.businessArea}}
                                            </option>
                                        </select>
                                        <!-- <p *ngIf="khcForm.controls['idea_business_area'].invalid && khcForm.controls['idea_business_area'].touched"  style=" font-size: medium; color: red ">
                                    Idea Business Area  is required
                                   </p> -->
                                    </div>
                                    <div class="col-md-6 col-css right_side_field-css"
                                    *ngIf="this.isClassification3Visible">
                                    <label class="label">Idea Classification 3</label>

                                    <select class="form-select form-select-md" aria-label=".form-select-sm example"
                                        [(ngModel)]="submitformmodel.ideaClassification3"
                                        formControlName="idea_classification3" required>
                                        <option value="" selected disabled hidden> Please select idea classification
                                        </option>
                                        <option [value]="item.classification3"
                                            *ngFor="let item of ideaClassification3">
                                            {{item.classification3}}</option>
                                    </select>
                                    <!-- <p *ngIf="khcForm.controls['idea_classification3'].invalid && khcForm.controls['idea_classification31'].touched"  style=" font-size: medium; color: red ">
                                Idea Classification 3 is required
                               </p> -->
                                </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">


                                    <div class="col-md-6 col-css">
                                        <label class="label">Anticipated Return on Investment (Optional)</label>

                                        <input type="text" (keypress)="keyPressNumbers($event)"
                                            class="form-control form-select-md" maxlength="20"
                                            [(ngModel)]="submitformmodel.returnOnInvestment"
                                            formControlName="investment_decimal" placeholder="">
                                    </div>

                                    <div class="col-md-6">

                                        <label class="label">Incremental Dollars</label>

                                        <input type="text" class="form-control form-control-md"
                                            [(ngModel)]="submitformmodel.incrementalDollars"
                                            placeholder="Estimated incremental dollars for the business?"
                                            formControlName="incremental_dollar" required>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">


                                    <div class="col-md-6">

                                        <label class="label">Estimated Timeframe to Implement Idea (Optional)</label>

                                        <input type="text" class="form-control form-control-md" maxlength="20"
                                            [(ngModel)]="submitformmodel.estimatedMonthstoImplementIdea"
                                            formControlName="estimatedMonthstoImplementIdea"
                                            placeholder="Estimated timeframe to implement your idea (months)?">
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_desc_supdoc-css">Description of Idea </label>

                            </div>



                        </div>
                        <div class="row">
                            <div class="col-md-12">


                                <textarea class="form-control textarea-css" id="exampleFormControlTextarea1" rows="3"
                                    [(ngModel)]="submitformmodel.descriptionOfIdea"
                                    placeholder='Please describe the idea in detail: Alternatively, the idea description can be included as an attachment at the bottom of this form.If using attachment, Please enter "as per attachment" in this box.'
                                    formControlName="description_of_idea" required></textarea>
                                <!-- <span *ngIf="khcForm.controls['description_of_idea'].invalid && khcForm.controls['description_of_idea'].touched"  style=" font-size: medium; color: red ">
                            Idea Business Area  is required
                        </span> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 ">
                                <label class="label_desc_supdoc-css">Supporting Documents </label>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row" style="margin-top: 0%">

                                    <label class="attachment_text_css">Please attach any screenshots, sample reports,
                                        email threads or any other reference documents</label>
                                    <div class="col-md-8">

                                        <div class="file_attachment-css" style="padding-bottom: 10%;">
                                            <label class="uploadFile">

                                                <!-- <div *ngIf="(myFiles ==0)"> -->
                                                <div>

                                                    <i class="glyphicon glyphicon-paperclip attachment_text_css"><img
                                                            class="attachment_img-css"
                                                            src="assets/image/attachment_logo.svg"
                                                            style="margin-top: 1%;"> Attach file</i>
                                                    <div *ngIf="myFiles.length==0" class="attachment_text_css"
                                                        style="padding-left: 5px;">There is nothing attached.</div>
                                                </div>


                                                <div>

                                                </div>

                                                <!-- <span class="filename label">Attach File</span> -->
                                                <input type="file" id="formFileMultiple" multiple
                                                    [(ngModel)]="submitformmodel.files" formControlName="attachFiles"
                                                    class="FileUpload" accept="." (change)="getFileDetails($event)" />
                                                <!-- <pre>{{myFiles | json}}</pre> -->


                                            </label>
                                          
                                            <div *ngFor="let file of myFiles; let i = index"
                                                class="attachment_text_css">
                                                <span style="padding-left: 15px;"> {{file.name}} </span><img
                                                    width="25px" style="padding-left: 2px;cursor: pointer !important;"
                                                    (click)="removeItem(i)" src="assets/image/deleteicon.png">
                                            </div>
                                            <!-- <div *ngIf="myFiles.length==0" class="attachment_text_css" style="padding-left: 15px;">There is nothing attached.</div> -->
                                        </div>

                                    </div>

                                    <div class="col-md-4 attachment_text_css" style="padding-top:4%;">
                                        <!-- <span  [ngClass]="errorFlagSIze? 'error': 'red'"> Max file size 50MB <br></span>
                                        <span [ngClass]="errorFlag? 'error': 'red'">Attach upto 5 files</span><br> -->
                                        <div *ngIf="(this.errorFlagSIze ==false)" style="margin-left: 2%;">Attach upto 5 files</div>
                                        <!-- <span><br></span> -->
                                        <div *ngIf="(this.errorFlag==false)">(10 MB file size limit)</div>
                                        <div *ngIf="(this.errorFlagSIze ==true)"
                                            [ngClass]="errorFlagSIze? 'error': 'red'"> Max file size exceeds<br></div>
                                        <div *ngIf="(this.errorFlag==true)" [ngClass]="errorFlag? 'error': 'red'">
                                            Max.number files reached <br></div>
                                        <!-- <span
                       *ngIf="(this.errorFlag==true)"
                        style="color: red;">Please Enter valid file count</span> <br>
                        <span
                        *ngIf="(this.errorFlagSIze==true)"
                        style="color: red;">Max Files Size Should be below 50MB</span>  -->
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="row" style="margin-top: 2%;">
                            <div class="col-md-12 col-css">

                                <input type="text" class="form-control" id="formGroupExampleInput"
                                    [(ngModel)]="submitformmodel.url" formControlName="idea_url"
                                    placeholder="If you would like to include a URL to support the idea, please include it here">

                            </div>
                        </div>
                        <div class="row" style="margin-top: 3%;">
                            <div class="col-md-3 ">
                            </div>
                            <div class="col-md-6 col-css ">
                                <p class="label" style="text-align: center; margin-top: 1%; margin-bottom: 0%;"> <span
                                        style="color:#EA0808;">Note:</span> Please fill all required fields to Submit
                                    the form.</p>
                            </div>
                            <div class="col-md-3">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-8 col-css">


                                <p class="label" style="text-align: center;margin-bottom: 0%;"> If you'd like to learn
                                    about what happens after you submit your idea click <span (click)="onClickHere()"
                                        style="text-decoration: underline; color:#1D3C6D; cursor: pointer;">here</span>
                                </p>



                            </div>
                            <div class="col-md-2">

                            </div>
                        </div>
                        <!-- Footer -->
                        <div class="row">

                            <div class="col-md-12">

                                <footer class="page-footer text-center  font-small blue">
                                    <div class="footer-copyright center  py-3">
                                        <!-- <button (click)="uploadFile()" >upload File</button> -->
                                        <button type="button" class="btn btn-secondary  Submitbtn"
                                            [disabled]="!khcForm.valid ||(errorFlagSIze==true || errorFlag==true)"
                                            (click)="onClickDefault()"
                                            (click)="onSubmitFormattachment()">Submit</button>
                                        <button type="button" class="btn btn-secondary  Cancelbtn" (click)="resetForm()"
                                            value="Reset">Cancel</button>
                                        <!-- <button type="button" class="btn btn-secondary  Cancelbtn" (click)="onFileUpload()"
                                            value="Reset">Upload</button> -->

                                    </div>
                                </footer>

                            </div>
                        </div>

                    </form>

                </div>
                <div class="col-md-1">

                </div>
            </div>

            <div class="col-md-1">
            </div>
        </div>


    </div>

    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template">
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8" style="text-align:center">

                <img class="thunder-logo-css" src="assets/image/thunder-loader.gif">

            </div>
            <div class="col-md-2">
            </div>
        </div>
    </ngx-loader>
</div>