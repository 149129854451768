import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent {
  title = 'kraft-heinz';
  constructor(private router: Router) { }

  ngOnInit() {

    let val = window.location.href;
    if (val.indexOf('/admin') != -1) {
      document.body.style.backgroundColor = "#EEEEF4";
    } else {
      document.body.style.backgroundColor = "#fff";
    }
  }

  hasRoute(route: string) {

    let val = window.location.href;
    if (val.indexOf('/admin') != -1) {
      document.body.style.backgroundColor = "#EEEEF4";
    } else {
      document.body.style.backgroundColor = "#fff";
    }
    return this.router.url.includes(route);
  }

  //Function for on Click Home
  onClickHome() {
    this.router.navigate(['introduction']);
  }
}
