<div class="wrapper " >
  <div *ngIf=" !hasRoute('admin')&& !hasRoute('admin-login') && !hasRoute('admin-dashboard') " class="static" style="background: #F8F8F8;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);">
    <div class="header">
      <div class="row header-padding-css" style="background:#F8F8F8">
        <div class="col-md-12">
          <div>
            <div style="width:100%;display: flex;align-items: center;justify-content: center;" class="header-wrapper">
              <div style="width:30%"> <img class="khc_logo-css" style=" cursor: pointer;float: left; margin-left: 10%;"
                  src="assets/image/kraftheinz-logo2.png" (click)="onClickHome()"></div>
              <div style="padding-left: 0%; width:50%; text-align: -webkit-center;" class="heading">
                <span *ngIf="hasRoute('form')">Disruption Idea Collection Form </span>
              </div>
              <div class="home_icon-css" style="width:20% "><img src="assets/image/Vector.png"
                  *ngIf="!hasRoute('introduction')" class="home_icon-css"
                  style="cursor: pointer; float: right; margin-right: 15%;;padding-top: 0px;" (click)="onClickHome()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>


</div>