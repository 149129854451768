
 <div class="container-fluid">

  <div class="row" >
 <div class="col-md-12">
     <div  class="div_margin-css">
        <div class="row privacy_margin-css" >
          <div class="col-md-12 privacy_policy-css" >
            Privacy policy
          </div>
        </div>
         <div class="row content_padding-css">
            <div class="row">
              <div class="col-md-12 heading-css" >
                Privacy notice
            </div>
            </div>
            
            </div>
            <div class="row">
              <div class="col-md-12 general_font-css" >
                <p>  This Privacy Notice describes how H. J. Heinz Company Australia Limited, Golden Circle Limited, Cerebos
                  (Australia) Limited, Heinz Wattie’s Limited and Cerebos Gregg’s Limited (Kraft Heinz), use personal
                  information you provide by entering or submitting a Disruptive Idea to the ANZ Disruption Den through
                  the QR code printed on Disruption Den posters at manufacturing sites, email or link communicated by
                  ANZ Communications or by completing the from on the Disruption Den intranet site.</p>
               </div>
            </div>
            <div class="row">
              <div class="col-md-12 heading-css">
                  <span>Information we collect about you</span>
              </div>
              
            </div>
            <div class="row ">
              <div class="col-md-12 general_font-css">
                <p> To enable us to receive, review and investigate ideas submitted, we are requesting submitters to
                  provide us with the following information:</p>
               </div>
            </div>
        
            <div class="general_font-css" style="margin-left: 1%;">
              <li >Applicable country in ANZ</li>
              <li  style="margin-top:1%;">Kraft Heinz manufacturing site or department</li>
              <li  style="margin-top:1%;">Your name (optional)</li>
              <li  style="margin-top:1%;">Email address (optional)</li>
            </div>
           
            <div class="row content_padding-css" style="margin-top: 2%;" >
              <div class="col-md-12 heading-css">
                   <span>Use and storage of personal information </span>
                 
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 general_font-css">
                <p>The information you will provide will be used only for the following purposes:</p>
              </div>
            </div>
            <div class="general_font-css" style="margin-left: 1%;">
              <li  >receiving and keeping a record of ideas submitted;</li>
              <li  style="margin-top:1%;">reviewing and (if applicable) further review or investigation of ideas submitted.</li>
              
            </div>
            <div class="row content_padding-css" style="margin-top: 1%">
              <div class="col-md-12 general_font-css">
                <p>Kraft Heinz will not disclose this information to any external parties (including government authorities) unless required to by law. Access to this information will be restricted to Disruption Den ambassadors,
                  Kraft Heinz management and employees involved in reviewing or assessing ideas submitted and only
                  those who have a valid business justification on a confidential &#39;need-to-know&#39; basis.</p>
                </div>
            </div>
            <div class="row content_padding-css">
              <div class="col-md-12 general_font-css">
                <p>Kraft Heinz will not disclose this information to any external parties (including government authorities) unless
                  required by law. If you choose not to provide this information or provide incomplete information, this may mean your idea will not be accepted for review by the Disruption Den.</p>
                
                
              </div>
            </div>
            <div class="row content_padding-css">
              <div class="col-md-12 general_font-css">
                <p>The information you provide will be stored for no longer than 12 months, unless otherwise required by law
                  and will be handled in accordance with applicable privacy laws.</p>
                
                
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 heading-css">
                  <span> Access to your personal information </span>
                  
              </div>
            </div>
          
             <div class="row">
              <div class="col-md-12 general_font-css">
                <p>If you have any queries or concerns about providing the personal information requested when
                  submitting your idea to the Disruption Den or if you would like a copy of some or all of your personal
                  information provided through this process, please contact the Privacy Team at
                  <a href = "mailto:PrivacyAU@kraftheinz.com">PrivacyAU@kraftheinz.com</a>, <a href = "mailto:PrivacyNZ@kraftheinz.com">PrivacyNZ@kraftheinz.com</a> or <a href = "mailto:Privacy@kraftheinz.com.">Privacy@kraftheinz.com.</a> </p>
              </div>
            </div>
            <div class="row content_padding-css">
              <div class="col-md-12 general_font-css">
                <p>For more information about Kraft Heinz&#39; privacy practices, including how to exercise your personal rights
                  under applicable law please visit the local Kraft Heinz website in your country, and click on our Privacy
                  Policy.</p>
              </div>
            </div>
            <div class="row content_padding-css">
              <div class="col-md-12 general_font-css">
                </div>
            </div>
            <div class="row" >
              
              <div class="col-md-12">
                <footer class="page-footer text-center  font-small blue">
                  <div class="footer-copyright center  py-3 ">
                         
                    <button type="button" class="btn btn-secondary backbtn"  routerLink=""  style="margin-right: 2%;">Back</button>
                    <button type="button" class="btn btn-primary nextbtn" (click)="onClickNext()" >Next</button>
                        
                  </div>
                  </footer>
              </div>
           
              
             </div>
            </div>
      </div>
     
  </div>

</div>
<div class="row">
  <div class="col-md-1">

  </div>
  <div class="col-md-10">
    <form>
      <div class="row" >
              
        <div class="col-md-12">
          <footer class="page-footer text-center  font-small blue footer">
            <div class="footer-copyright center  py-3 footer">
                   
              <button type="button" class="btn btn-secondary backbtn"  routerLink=""  style="margin-right: 1%;">Back</button>
              <button type="button" class="btn btn-primary nextbtn" (click)="onClickNext()" >Next</button>
                  
            </div>
            </footer>
        </div>
     
        
       </div>
    </form>

  </div>
  <div class="col-md-1">

  </div>
</div>