<div>
    
  <img  class="disruption_den-css" width="100%" src="assets/image/bg-01.jpg">

</div>

<div class="container-fluid">
  <body>
  <div class="row">
  <div class="col-md-12">
       <div class="row" style="margin-top: 2%">
          
         
            <div class="col-md-12"  style="text-align: center;" >
                
              <span class="welcome_Heading-css" style="text-align: center " >Welcome to the <span class="red">Disruption Den!</span></span>
            <div class="heading-css" style="text-align: center " >Here at  <span class="blue">Kraft</span> <span class="red">Heinz</span> we aim to sustainably grow by <span class="blue">disrupting&nbsp;</span> <span class="red">tomorrow</span>.</div> 
          <div class="heading-css">To do this we&nbsp;<span style="color: #E53B3B;">need&nbsp;</span>your<span style="color: #183D6E;"> help</span>.</div>

          </div>
           
          </div>
         <div class="row" style="margin-top:1% ;">
          
            <div class="col-md-5">
            
            </div>
            <div class="col-md-2" style="text-align:center ;" >
                <img class="thunder_icon-css" src="assets/image/light 1.png">
            </div>
            <div class="col-md-5">
            
            </div>
          
          </div>
          <div  style="margin-left: 2%;">
          <div class="row" style="margin-top: 2%;">
          
           
            <div class="col-md-12 heading-css" >
                We want to hear your <span style="color: #E53B3B;">ideas</span> for the <span style="color: #183D6E;">company</span> - the more <span style="color: #E53B3B;">disruptive</span> and <span style="color: #183D6E;">unconventional</span> the better.
                
            </div>
           
          
          </div>
          <div class="row" style="margin-top: 1%;">
          
           <div class="col-md-12" >
          <span class="general_font-css">   These ideas can be about absolutely anything. You might have an idea for a new product, a new way of working in our factories, or a way to update our processes to increase efficiency. We want to hear them all - there are no bad ideas!</span>
              
            </div>
           
          </div>
          <div class="row" style="margin-top:1%">
          <div class="col-md-12 fontSize" >
          <span class="dynamic_List-css"> Monthly/Quarterly Business Problems</span>
          </div>
           </div>
          <div class="row" style="margin-top: 1%">
          <div class="col-md-12" >
                <span class="general_font-css"> To help inspire your disruptive ideas here are some of the big issues we are facing this month/quarter. We'd love to hear your solutions.
                </span>
               
                <ul type="number" class="dynamic_List-css" *ngFor="let item of this.businessSolutionGrid">
                  
                    <li style="margin-top:1%; margin-left: -1%;" >{{item.solution}}</li>
                   
                </ul>
              </div>
            </div>
          <div class="row">
          <div class="col-md-12"  style="margin-top:1% ;">
                <span class="general_font-css"> We look forward to reading your Disruptive ideas!</span>
               
               </div>
           </div>
          </div>
       
         
           <div class="col-md-1">

      </div>
      <div class="row">
        <footer class="page-footer text-center  font-small blue  " style="margin-top: -1%;">
      <div class="footer-copyright center  py-3">
             
      <button type="button" class="btn btn-secondary btn-sm submitbtn-css" width="100%"   (click)="onClickSubmit()" >Submit your ideas here</button>
            
      </div>
      </footer>
     </div>
</div>
</div>

  </body>
  
</div>