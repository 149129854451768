import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CallProviderService {

  constructor(private http: HttpClient) { }

  get(url: any) {
    const apiURL = environment.endPointUrl + url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(apiURL);
  }
  post(url: any, data: any) {
    const apiURL = environment.endPointUrl + url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.post(apiURL, data, httpOptions);
  }
  //for form data type request
  postDataOnSubmit(url: any, data: any) {
    const apiURL = environment.endPointUrl + url;
    const httpOptions = {
      headers: new HttpHeaders({

      }),
    };
    return this.http.post(apiURL, data, httpOptions);
  }
  put(apiUrl: any, data: any) {
    const apiURL = environment.endPointUrl + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.put(apiURL, data, httpOptions);
  }
  
  putUploadFile(apiUrl: any, data: any) {
    const apiURL = environment.endPointUrl + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'octet-stream'
      }),
    };
    return this.http.put(apiURL, data, httpOptions);
  }
  delete(apiUrl:any) {
    const apiURL = environment.endPointUrl + apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // AuthenticationKey: `${CryptoJS.AES.decrypt(
        //   localStorage.getItem('UAuthToken') == null
        //     ? ''
        //     : localStorage.getItem('UAuthToken'),
        //   environment.EncryptionKey
        // ).toString(CryptoJS.enc.Utf8)}`,
      }),
    };
    return this.http.delete(apiURL, httpOptions);
}
}

