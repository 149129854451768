import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { KhcIdeaDisruptionService } from 'src/app/shared/khc-idea-disruption.service';
import { SubmitFormModel, FileUpload } from '../Model/form.model';
import { SubjectSubscriber } from 'rxjs/internal/Subject';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { RxwebValidators, RxFormBuilder, file, fileSize, async } from '@rxweb/reactive-form-validators';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import Swal from "sweetalert2";
import { HttpBackend } from '@angular/common/http'
import { Observable } from 'rxjs';
import { Console } from 'console';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { AnyARecord } from 'dns';



@Component(
  {
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    // Supported from angular version12
    styles: [
      `
    @media only screen and (max-width: 1025px) { 
   .div{
       color:red;
   }
}
    
    `
    ]
  })
export class FormComponent implements OnInit {
  toFile: any;
  @Input() name2: any;
  toastr: any;
  isFileUpload: any;
  // isfileselected: boolean;
  uploadedfileresponse: any;

  //Object Creation For Submit Form Model
  submitformmodel: SubmitFormModel = new SubmitFormModel();
  fileupload: FileUpload = new FileUpload();

  //Variable Declareition   
  countryListGrid: any;
  ideaDepartmentGrid: any;
  yourDepartmentGrid: any;
  ideaClassification1: any;
  ideaClassification2: any;
  ideaClassification3: any;
  ideaBusinessArea: any;
  ideaDepartmentValue: any = [];
  ideaDGrid: any;
  ideaDepartmentFlag: boolean = false;
  x: any;
  myFiles: any = [];
  base64Files: any = [];
  sMsg: string = '';
  fileBlob: any
  name: any = [];
  myFilesNew: any = [];

  submitted: any;
  imgName: any = [];
  imgSize: any = [];
  finalFilesPost: any = [];
  errorFlag: boolean = false;
  errorFlagSIze: boolean = false;
  sumFileSize = 0
  show = false;
  fullScreen = true;
  template = ``
  percentDone: any;
  uploadSuccess: any;
  filePostData: any
  elementObj: any
  preSignedURlres: any;
  formData: any;
  fileLocationS3: any;
  ideaDepartmentID: any
  selectedIdeaDepartment: any
  isClassification1Visible: boolean = false;
  isClassification2Visible: boolean = false;
  isClassification3Visible: boolean = false;
  ideaClassification1ID: any;
  selectedIdeaClassification1: any
  bussinessAreaID: any
  selectedIdeaBusinessArea: any

  //Function for Form validation
  khcForm = new FormGroup({
    country: new FormControl(''),
    oldPassword: new FormControl(''),
    name: new FormControl(''),
    your_name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[A-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    idea_department: new FormControl(''),
    your_department: new FormControl(''),
    idea_classification2: new FormControl(''),
    idea_classification1: new FormControl(''),
    idea_classification3: new FormControl(''),
    idea_business_area: new FormControl(''),
    description_of_idea: new FormControl(''),
    supporting_document: new FormControl(''),
    idea_url: new FormControl(''),
    investment_decimal: new FormControl(''),
    Filename: new FormControl(''),
    attachFiles: new FormControl(''),
    incremental_dollar: new FormControl(''),
    estimatedMonthstoImplementIdea: new FormControl(''),
  })


  get f() { return this.khcForm.controls; }






  constructor(private activatedRoute: ActivatedRoute, private handler: HttpBackend, private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private fb: FormBuilder, public khcIdeaDisruption: KhcIdeaDisruptionService) {
    //Function Calling
    this.onGetCountryList()
    this.onGetIdeaDepartment()
    this.onGetyourDepartment()
    this.onGetIdeaClassificationDepartment1()
    this.onGetIdeaClassificationDepartment2()
    this.onGetIdeaClassificationDepartment3()
    this.onGetideaBusinessArea();
  }

  ngOnInit(): void {
  }
  //Function for on Click Home
  onClickHome() {
    this.router.navigate(['']);
  }

  //Function for Reset Form
  resetForm(): void {
   this.isClassification1Visible = false;
  this.isClassification2Visible= false;
  this.isClassification3Visible= false;
    this.myFiles=[];
    this.khcForm.reset();
    this.khcForm.controls['country'].setValue('');
    this.khcForm.controls['idea_department'].setValue('');
    this.khcForm.controls['your_department'].setValue('');
    this.khcForm.controls['idea_classification1'].setValue('');
    this.khcForm.controls['idea_classification2'].setValue('');
    this.khcForm.controls['idea_classification3'].setValue('');
    this.khcForm.controls['idea_business_area'].setValue('');
    this.khcForm.controls['your_name'].setValue('');
    this.khcForm.controls['email'].setValue('');
    this.khcForm.controls['idea_url'].setValue('');
    this.khcForm.controls['investment_decimal'].setValue('');
    // this.khcForm.controls['attachFiles'].setValue('');
    this.khcForm.controls['description_of_idea'].setValue('');
    this.khcForm.controls['incremental_dollar'].setValue('');
    this.khcForm.controls['attachFiles'].setValue('');
    
  }
  //Function for click Here
  onClickHere() {
    this.router.navigate(['introduction']);

  }

  //Get Country List 
  onGetCountryList() {
    this.khcIdeaDisruption.getCountryList().toPromise()
      .then((res: any) => {
        this.countryListGrid = res.displayCountry

      }).catch((err) => {
        console.log(err);
      })
  }
  //Get Idea Department 
  onGetIdeaDepartment() {
    this.khcIdeaDisruption.getideaDepartment().toPromise()
      .then((res: any) => {
        this.ideaDepartmentGrid = res.displayIdeaDepartment

      }).catch((err) => {
        console.log(err);
      })
  }
  //Get Your Department 
  onGetyourDepartment() {
    this.khcIdeaDisruption.getyourDepartment().toPromise()
      .then((res: any) => {
        this.yourDepartmentGrid = res.displayDepartment

      }).catch((err) => {
        console.log(err);
      })
  }
  // onChangeIdeaDepartment(id:any){
  //   
  //   this.ideaDepartmentID=id

  // }





  //Visibility Method for Idea Department
  // selectedIdeaDepartment = '';
  // onSelectedIdeaDepart1(value: string,id:any): void {
  //   
  //   this.selectedIdeaDepartment = value;
  //   this.ideaDepartmentID=id
  //   }
  onSelectedIdeaDepart(e: any) {
    
    this.ideaDepartmentID = e.target.value;
    this.isClassification1Visible = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("isClassification1Visible"));
    if (this.isClassification1Visible == undefined || this.isClassification1Visible == null) {
      this.isClassification1Visible = false;
    }
    this.ideaDepartmentGrid.filter((data: any) => {
      if (data.id == this.ideaDepartmentID) {
        this.selectedIdeaDepartment = data.ideaDepartment
        this.submitformmodel.ideaDepartment=data.ideaDepartment
      }
      console.log("ID and name of idea department=>", this.ideaDepartmentID, this.selectedIdeaDepartment)
    }

    )
    console.log("Id is=>", this.ideaDepartmentID)
    this.onGetIdeaClassificationDepartment1()
  }
  //Get Idea Classification 1
  onGetIdeaClassificationDepartment1() {
    
    this.khcIdeaDisruption.getIdeaClassification1FormModule(this.ideaDepartmentID).toPromise()
      .then((res: any) => {
        this.ideaClassification1 = res.displayClassification1

      }).catch((err) => {
        console.log(err);
      })
  }

  // //Visibility Method for Idea Classification1
  // selectedIdeaClassification1 = '';
  // onSelectedIdeaClassification1(value: string): void {
  //   this.selectedIdeaClassification1 = value;
  // }
  onSelectedIdeaClassification1(e: any) {
  
    this.ideaClassification1ID = e.target.value;
    this.isClassification2Visible = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("isClassification2Visible"));
    if (this.isClassification2Visible == undefined || this.isClassification2Visible == null) {
      this.isClassification2Visible = false;
    }
    this.ideaClassification1.filter((data: any) => {
      if (data.id == this.ideaClassification1ID) {
        this.selectedIdeaClassification1 = data.classification1
        this.submitformmodel.ideaClassification1=data.classification1
      }
      console.log("ID and name of classification1=>", this.ideaClassification1ID, this.selectedIdeaClassification1)
    },
      this.onGetIdeaClassificationDepartment2()
    )
    console.log("Id is=>", this.ideaDepartmentID)

  }
  

  //Get Idea Classification 2
  onGetIdeaClassificationDepartment2() {
    
   
    this.khcIdeaDisruption.getIdeaClassification2FormModule(this.ideaClassification1ID).toPromise()
      .then((res: any) => {
        this.ideaClassification2 = res.displayClassification2
        console.log("classification 2=>>", this.ideaClassification2)

      }).catch((err) => {
        console.log(err);
      })
  }

  onSelectedIdeaBusinessArea(e: any) {
    
    this.bussinessAreaID = e.target.value;
    this.isClassification3Visible = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("isClassification3Visible"));
    if (this.isClassification3Visible == undefined || this.isClassification3Visible == null) {
      this.isClassification3Visible = false;
    }
    this.ideaBusinessArea.filter((data: any) => {
      if (data.id == this.bussinessAreaID) {
        this.selectedIdeaBusinessArea = data.businessArea
        this.submitformmodel.ideaBusinessArea=data.businessArea
      }
      console.log("ID and name of businessArea=>", this.bussinessAreaID, this.selectedIdeaBusinessArea)
    },
      this.onGetIdeaClassificationDepartment3()
    )
    console.log("Id is=>", this.bussinessAreaID)

  }
  //Get Idea Classification 3
  onGetIdeaClassificationDepartment3() {
    
    this.khcIdeaDisruption.getIdeaClassification3FormModule(this.bussinessAreaID).toPromise()
      .then((res: any) => {
        this.ideaClassification3 = res.displayClassification1

      }).catch((err) => {
        console.log(err);
      })
  }
  //Get Idea Business Area 
  onGetideaBusinessArea() {
    this.khcIdeaDisruption.getideaBusinessArea().toPromise()
      .then((res: any) => {
        this.ideaBusinessArea = res.displayBusinessArea

      }).catch((err) => {
        console.log(err);
      })
  }
  // Only Integer Numbers
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  //Method For getting All the file details
  getFileDetails(e: any) {
   
    this.errorFlag = false;
    this.errorFlagSIze = false;
    this.imgName = [];
    // this.myFiles = [];
    this.sumFileSize = 0;
   

    for (var i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      //this.base64Conv(file);
      this.myFiles.push(file);

    }
    if (this.myFiles.length > 5) {
      this.errorFlag = true;

    }
    console.log("Form Data File", this.myFiles)


    for (let v = 0; v < this.myFiles.length; v++) {
      this.imgName[v] = this.myFiles[v].name;


    }
    console.log("Image name------->", this.imgName);
    for (let j = 0; j < this.myFiles.length; j++) {

      this.imgSize[j] = this.myFiles[j].size;
      this.sumFileSize = this.sumFileSize + Number(this.imgSize[j] = this.myFiles[j].size);

    }
    console.log("File Size is------->", this.imgSize);
    console.log("Total File Size is------->", this.sumFileSize);


    if (this.sumFileSize > 10485760) {
      this.errorFlagSIze = true
    }
    this.finalFilesPost=JSON.parse(JSON.stringify(this.myFiles))
  }
  //Method for Submit Form
  onSubmitForm() {
 

    let uploadFiles: any = [];
    this.imgName.forEach(function (value: any, index: any) {

      uploadFiles.push({
        fileName: value

      })
      console.log("File details=>>", uploadFiles, index);
    });

    console.log(uploadFiles);




    this.submitformmodel.files = uploadFiles;

    this.khcIdeaDisruption.SubmitForm(this.submitformmodel).toPromise()
      .then((res) => {

        Swal.fire({
          imageUrl: 'assets/image/logo.svg',
          text: 'Your Idea has been submitted',
          imageHeight: 100,
          confirmButtonColor: "#1D3C6D",
          confirmButtonText: "Ok",
        }).then((result) => {
          this.router.navigate(['introduction']);
        });
      })
      .catch((err) => {
        console.log(err);
     
      });
  }

  //Base64 Conversion
  // base64Conv(event: any) {

  //   const file = event;
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {

  //     this.finalFiles.push({
  //       fileData: reader.result?.toString().replace(/^data:(.*,)?/, ""),
  //       filename: this.fileLocationS3
  //     });

  //   };
  // }

  //Code For Loader
  onClickDefault() {

    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {

      this.show = false
    }, 2000);
  }

  //Remove attached files 
  removeItem(index: number): void {
   
    this.finalFilesPost.splice(index, 1);
    this.sumFileSize = 0;
    this.errorFlag = false;
    this.myFiles.splice(index, 1);
    // this.finalFilesPost=JSON.parse(JSON.stringify(this.myFiles))
    //this.submitformmodel.files.splice(index, 1);
    for (let j = 0; j < this.myFiles.length; j++) {

      this.imgSize[j] = this.myFiles[j].size;
      this.sumFileSize = this.sumFileSize + Number(this.imgSize[j] = this.myFiles[j].size);
    }
    if (this.sumFileSize > 52428800) {
      this.errorFlagSIze = true

    }
    else {
      this.errorFlagSIze = false
    }

    if (this.myFiles.length > 5) {
      this.errorFlag = true;
    }


  }


  //File Upload Directly to S3
  uploadFile() {
  
    
    let fileCount = 0;
    // alert("working")
    const bucketName = 'khcid-wap-attachment';
    const bucket = new S3(
      {
        accessKeyId: 'AKIA3ZH3HRXS3UTZITAC',
        secretAccessKey: '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'
      }
    );
    for (var i = 0; i <  this.myFiles.length; i++) {
      const params = {
        Bucket: bucketName,
        Key: this.myFiles[i].name,
        Body:  this.myFiles[i],
        ACL: 'public-read',
        ContentType: this.myFiles[i].type
      };
      bucket.upload(params, (err: any, data: any) => {

        if (err) {
          console.log('There was an error uploading your file: ', err);
          return false;
        }

        console.log('Successfully uploaded file.', data);
        console.log('Successfully uploaded file.', data.Location);
        if (data) {
          fileCount = fileCount + 1;
        }

        if (fileCount == this.myFiles.length) {

          this.onSubmitForm();
        }
        this.fileLocationS3 = data.Location;
        console.log('Locatioin of uploaded file.', this.fileLocationS3);
        return true;
      });
    }

  }


  onSubmitFormattachment() {
     
    if (this.myFiles.length>0){
      this.uploadFile()
    }
    else{
    this.onSubmitForm();
    }
   
    
  }
}
