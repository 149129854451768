import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-info-page',
  templateUrl: './privacy-info-page.component.html',
  styleUrls: ['./privacy-info-page.component.css']
})
export class PrivacyInfoPageComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
//Function for on Click Home
onClickHome(){
  this.router.navigate(['']);
}
onClickNext(){
  this.router.navigate(['form'])
}
}
