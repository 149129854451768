import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroductionPageComponent } from './modules/module-1/component/introduction-page/introduction-page.component';
import { PrivacyInfoPageComponent } from './modules/module-2/components/privacy-info-page/privacy-info-page.component';
import { FormComponent } from './modules/module-3/component/form/form.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';


const routes: Routes = [

  { path: '', redirectTo: 'introduction', pathMatch: 'full' },
  {
    path: 'introduction',
    component: IntroductionPageComponent,

  },
  {
    path: 'Privacypolicy',
    component: PrivacyInfoPageComponent,

  },
  {
    path: 'form',
    component: FormComponent,

  },

  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top', enableTracing: true

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
