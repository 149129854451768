import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroductionPageComponent } from './modules/module-1/component/introduction-page/introduction-page.component';
import { PrivacyInfoPageComponent } from './modules/module-2/components/privacy-info-page/privacy-info-page.component';
import { FormComponent } from './modules/module-3/component/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from
  '@angular/platform-browser/animations';

import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
// Importing forms module








@NgModule({
  declarations: [
    AppComponent,
    IntroductionPageComponent,
    PrivacyInfoPageComponent,
    FormComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxLoaderModule

  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
