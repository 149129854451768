import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KhcIdeaDisruptionService } from 'src/app/shared/khc-idea-disruption.service';

@Component({
  selector: 'app-introduction-page',
  templateUrl: './introduction-page.component.html',
  styleUrls: ['./introduction-page.component.css'],
  
   styles: [
    `
    @media only screen and (max-width: 1025px) { 
   .div{
       color:red;
   }
}
    
    `
  ]
})
export class IntroductionPageComponent implements OnInit {
  businessSolutionGrid:any;
  imageUrl="assets/image/bg_img.jpg"

  constructor(private router :Router,public khcIdeaDisruption: KhcIdeaDisruptionService) { 
    this. onGetbusinessSolution()
  }

  ngOnInit(): void {
  }
  onClickSubmit(){
  
this.router.navigate(['Privacypolicy']);
  }

  onGetbusinessSolution(){
    this.khcIdeaDisruption.getBusinessSolution().toPromise()
    .then((res:any)=>{
      this.businessSolutionGrid=res.displaySolution

      console.log("businessSolution=>" , this.businessSolutionGrid)

    }).catch((err)=>{
      console.log(err)
    })
  }
}
