


export class SubmitFormModel
{

            requestID: string="";
            dateOfRequest:string= "2022-07-22";
            country: string="";
            name: string="";
            emailAddress: string="";
            yourDepartment:string=""
            ideaDepartment: string="";
            ideaClassification1: string="";
            ideaClassification2: string="";
            ideaClassification3: string="";
            ideaBusinessArea: string="";
            returnOnInvestment: string="";
            incrementalDollars : string="";
            estimatedMonthstoImplementIdea:string="";
            descriptionOfIdea: string="";
            url:string="";
            files:any=[]
           
}
export class FileUpload{
    files:any=[]
       
}
// export class FileModel{
//     files!: [
//         {
//             "fileData": "";
//             "fileName": "";
//         }
//     ];
// }
