import { Injectable } from '@angular/core';
import { CallProviderService } from '../HttpCallProvider/call-provider.service';
import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';
import { Http2ServerRequest } from 'http2';
import { HttpClient } from '@angular/common/http';
import { data } from 'jquery';
// import entire SDK

// import * as S3 from 'aws-sdk/clients/s3';



@Injectable({
  providedIn: 'root'
})
export class KhcIdeaDisruptionService {
  preSignedURlres: any
  Username:any;

  constructor(private callProvider: CallProviderService) { }
//Login API for Admin Portal
loginAdminPortal(data:any){
  const apiURL ='admin/login';
  return this.callProvider.post(apiURL,data);
}

  //Get Country List API link
  getCountryList() {
    const apiURL = 'admin/listCountries';
    return this.callProvider.get(apiURL);
  }
  //Get Idea Department API link
  getideaDepartment() {
    const apiURL = 'admin/listIdeaDepartments';
    return this.callProvider.get(apiURL);
  }
  //Get Your Department API link
  getyourDepartment() {
    const apiURL = 'admin/listDepartments';
    return this.callProvider.get(apiURL);
  }
  //Get Idea Classification1 API link
  getIdeaClassification1() {
    const apiURL = 'admin/getClassification1';
    return this.callProvider.get(apiURL);
  }
  //Get Idea Classification1 API link for Form Module
  getIdeaClassification1FormModule(id:any) {
    const apiURL = '/admin/classification1?ideaDepartmentId='+id;
    return this.callProvider.get(apiURL);
  }
   //Get Idea Classification2 API link for Form Module
   getIdeaClassification2FormModule(id:any) {
    const apiURL = 'admin/classification2?classification1Id='+id;
    return this.callProvider.get(apiURL);
  }
     //Get Idea Classification3 API link for Form Module
     getIdeaClassification3FormModule(id:any) {
      const apiURL = 'admin/classification3?businessAreaId='+id;
      return this.callProvider.get(apiURL);
    }
  //Get Idea Classification2 API link
  getIdeaClassification2() {
    const apiURL = 'admin/getclassification2';
    return this.callProvider.get(apiURL);
  }
  //Get Idea Classification3 API link
  getIdeaClassification3() {
    const apiURL = 'admin/getClassification3';
    return this.callProvider.get(apiURL);
  }

  //Get Idea Business Area API link
  getideaBusinessArea() {
    const apiURL = 'admin/listBusinessArea';
    return this.callProvider.get(apiURL);
  }


  SubmitForm(modelData: any) {
    
    const apiURL = 'form/submit';
    return this.callProvider.post(apiURL, modelData);
  }
  uploadFile(fileData: any) {
    const apiURL = 'form/s3PresignedURL';
    return this.callProvider.post(apiURL, fileData);
  }
  uploadPreSignedURL(data: any) {
    const apiURL = this.preSignedURlres;
    return this.callProvider.putUploadFile(apiURL, data);
  }
  getBusinessSolution() {
    const apiURL = 'admin/businessSolution';
    return this.callProvider.get(apiURL);
  }
  addCountryList(data:any){
    const apiURL ='admin/addCountry'
    return this.callProvider.post(apiURL,data)
  }
  updateCountryList(data:any){
    const apiURL ='admin/updateCountry'
    return this.callProvider.put(apiURL,data)
}
deleteCountry(id:number){
  const apiURL ='/admin/deleteCountry?id='+id
  return this.callProvider.delete(apiURL)
}

//End Point of add Department
addDepartment(data:any){
  const apiURL='admin/addDepartments'
  return this.callProvider.post(apiURL,data)
}
//End point For Edit Department
editDepartment(data:any){
  const apiURL='admin/updateDepartments'
  return this.callProvider.put(apiURL,data)
}
//End point for Delete Department
deleteDepartment(id:number)
{
  const apiURL='admin/deleteDepartments?id=' +id
  return this.callProvider.delete(apiURL)
}
 addIdeaDepartment(data:any){
  const apiURL='admin/addIdeaDepartments'
  return this.callProvider.post(apiURL,data)
 }
 editIdeaDepartment(data:any){
  const apiURL='admin/updateIdeaDepartments'
  return this.callProvider.put(apiURL,data)
 }
 deleteIdeaDepartment(id:number){
  const apiURL='admin/deleteIdeaDepartments?id='+id
  return this.callProvider.delete(apiURL)

 }
 addBusinessArea(data:any){
  const apiURL='admin/addBusinessArea'
  return this.callProvider.post(apiURL,data)
 }
 editBusinessArea(data:any){
  const apiURL='admin/updateBusinessArea'
  return this.callProvider.put(apiURL,data)
 }
 deleteBusinessArea(id:number){
  const apiURL='admin/deleteBusinessArea?id='+id
  return this.callProvider.delete(apiURL)

 }
 addIdeaClassification1(data:any){
  const apiURL='admin/addClassification1'
  return this.callProvider.post(apiURL,data)
 }
 editIdeaClassification1(data:any){
  const apiURL='admin/updateClassification1'
  return this.callProvider.put(apiURL,data)
 }
 deleteIdeaClassification1(id:number){
  const apiURL='/admin/deleteClassification1?id='+id
  return this.callProvider.delete(apiURL)
}
addIdeaClassification2(data:any){
  const apiURL='admin/addClassification2'
  return this.callProvider.post(apiURL,data)
 }
 editIdeaClassification2(data:any){
  const apiURL='admin/updateClassification2'
  return this.callProvider.put(apiURL,data)
 }
 deleteIdeaClassification2(id:number){
  const apiURL='admin/deleteClassification2?id='+id
  return this.callProvider.delete(apiURL)
}
addIdeaClassification3(data:any){
  const apiURL='admin/addClassification3'
  return this.callProvider.post(apiURL,data)
 }
 editIdeaClassification3(data:any){
  const apiURL='admin/updateClassification3'
  return this.callProvider.put(apiURL,data)
 }
 deleteIdeaClassification3(id:number){
  const apiURL='admin/deleteClassification3?id='+id
  return this.callProvider.delete(apiURL)
}
businessSolution(){
  const apiURL = 'admin/businessSolution';
  return this.callProvider.get(apiURL);
}
addbusinessSolution(data:any){
  const apiURL='admin/addBusinessSolution'
  return this.callProvider.post(apiURL,data)
 }
 editbusinessSolution(data:any){
  const apiURL='admin/updateBusinessSolution'
  return this.callProvider.put(apiURL,data)
 }
 deletebusinessSolution(id:number){
  const apiURL='admin/deleteBusinessSolution?id='+id
  return this.callProvider.delete(apiURL)
}
}
